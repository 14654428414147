<template>
    <div class="p-4 space-y-4">
        <h1 class="text-2xl">Assets social</h1>

        <div class="mb-2">
            <select v-model="assetType">
                <option value="banner-adv">Banner Adv</option>
                <option value="ig-story">Instagram story</option>
                <option value="fb-post">Facebook post</option>
                <option value="ig-feed">Instagram post</option>
            </select>
        </div>

        <div>
            <button v-if="!saveAllStatus.in_progress" :disabled="saveAllStatus.in_progress" type="button" @click="saveAll" class="text-white px-6 py-2 bg-gray-500 ">Salva tutti</button>
            <div v-else>
                Salvataggio in corso, la generazione delle immagini richiede del tempo.<br>
                Fatti {{ saveAllStatus.done }} di {{ saveAllStatus.total }}
            </div>
        </div>


        <h1 class="text-2xl">Miglior live</h1>

        <div v-if="assetType !== null" class="relative w-full overflow-auto" style="height: 450px;">
            <div class="absolute top-0 left-0 flex item-center space-x-4 transform origin-top-left" :style="{scale: scaleRatio}">
                <div v-for="(item, idx) in live" :key="idx">
                    <div class="flex flex-col space-y-12">
                        <banner-adv
                            :id="'banner_adv_' + idx"
                            v-if="assetType === 'banner-adv'"
                            class="banner_adv"
                            :data-file-name="upperCaseSlugify(item.artist_name) + '-rockol-awards-' + year + '-miglior-artista-live-banner-adv'"
                            :name="item.artist_name"
                            :album-name="null"
                            :year="year"
                            layout="live"
                            :img="item.photo_big"
                        />
                        <instagram-story
                            :id="'live_image_' + idx"
                            v-if="assetType === 'ig-story'"
                            class="ig_story"
                            :data-file-name="upperCaseSlugify(item.artist_name) + '-rockol-awards-' + year + '-miglior-artista-live-IG-STORY'"
                            :name="item.artist_name"
                            :album-name="null"
                            :year="year"
                            layout="live"
                            :img="item.photo_big"
                        />
                        <instagram-post
                            :id="'live_image_' + idx"
                            v-if="assetType === 'ig-feed'"
                            class="ig_story"
                            :data-file-name="upperCaseSlugify(item.artist_name) + '-rockol-awards-' + year + '-miglior-artista-live-IG-FEED'"
                            :name="item.artist_name"
                            :album-name="null"
                            :year="year"
                            layout="live"
                            :img="item.photo_big"
                        />

                        <facebook-post
                            :id="'live_image_' + idx"
                            v-if="assetType === 'fb-post'"
                            class="ig_story"
                            :data-file-name="upperCaseSlugify(item.artist_name) + '-rockol-awards-' + year + '-miglior-artista-live-FB-FEED'"
                            :name="item.artist_name"
                            :album-name="null"
                            :year="year"
                            layout="live"
                            :img="item.photo_big"
                        />

                        <button data-save-button type="button" class="text-5xl bg-gray-500 p-12 text-white"
                                @click="save('#live_image_' + idx)">Salva
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <h1 class="text-2xl">Miglior album</h1>

        <div v-if="assetType !== null" class="relative w-full overflow-auto" style="height: 450px;">
            <div class="absolute top-0 left-0 flex item-center space-x-4 transform origin-top-left" :style="{scale: scaleRatio}">
                <div v-for="(item, idx) in album" :key="idx">
                    <div class="flex flex-col space-y-12">
                        <banner-adv
                            :id="'banner_adv_' + idx"
                            v-if="assetType === 'banner-adv'"
                            class="ig_story"
                            :data-file-name="upperCaseSlugify(item.artist_name) + '-rockol-awards-' + year + '-miglior-album-banner-adv'"
                            :name="item.artist_name"
                            :album-name="item.record_title"
                            :year="year"
                            layout="album"
                            :img="item.photo_big"
                        />
                        <instagram-story
                            :id="'album_image_' + idx"
                            v-if="assetType === 'ig-story'"
                            class="ig_story"
                            :data-file-name="upperCaseSlugify(item.artist_name) + '-rockol-awards-' + year + '-miglior-album-IG-STORY'"
                            :name="item.artist_name"
                            :album-name="item.record_title"
                            :year="year"
                            layout="album"
                            :img="item.photo_big"
                        />
                        <instagram-post
                            :id="'album_image_' + idx"
                            v-if="assetType === 'ig-feed'"
                            class="ig_story"
                            :data-file-name="upperCaseSlugify(item.artist_name) + '-rockol-awards-' + year + '-miglior-album-IG-FEED'"
                            :name="item.artist_name"
                            :album-name="item.record_title"
                            :year="year"
                            layout="album"
                            :img="item.photo_big"
                        />
                        <facebook-post
                            :id="'album_image_' + idx"
                            v-if="assetType === 'fb-post'"
                            class="ig_story"
                            :data-file-name="upperCaseSlugify(item.artist_name) + '-rockol-awards-' + year + '-miglior-album-FB-FEED'"
                            :name="item.artist_name"
                            :album-name="item.record_title"
                            :year="year"
                            layout="album"
                            :img="item.photo_big"
                        />

                        <button data-save-button type="button" class="text-5xl bg-gray-500 p-12 text-white"
                                @click="save('#album_image_' + idx)">Salva
                        </button>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import {
    BEST_ITALIAN_ARTIST_SHOWS_POLL_NAME,
    BEST_ITALIAN_RECORDS_POLL_NAME,
    getCandidatesPromise,
} from '../../../shared/common';
import InstagramStory from "./InstagramStory";
import InstagramPost from "./InstagramPost";
import FacebookPost from "./FacebookPost";
import domtoimage from 'dom-to-image';
import {saveAs} from 'file-saver';
import JSZip from 'jszip';
import BannerAdv from "./BannerAdv";

const getCandidates = async (pollName) => {
    const response = await getCandidatesPromise(pollName);

    let candidatesFromApi = response.data ? response.data : [];

    return candidatesFromApi;
};

export default {
    components: {BannerAdv, InstagramStory, InstagramPost,FacebookPost},
    data() {
        return {
            year: process.env.VUE_APP_AWARDS_YEAR,
            assetType: 'fb-post',
            saveAllStatus: {
                in_progress: false,
                total: 0,
                done: 0,
            },
            album: [],
            live: [],
        }
    },
    methods: {
        async imageToBlob(htmlSelector) {
            const node = document.querySelector(htmlSelector);
            return domtoimage.toBlob(node);
        },
        async save(htmlSelector) {
            const node = document.querySelector(htmlSelector);
            const fileName = node.getAttribute('data-file-name');
            const blob = await this.imageToBlob(htmlSelector);
            saveAs(blob, fileName + '.png');
        },
        async saveAll() {
            this.saveAllStatus.in_progress = true;
            const zip = new JSZip();
            const buttons = document.querySelectorAll('.ig_story');
            this.saveAllStatus.total = buttons.length;

            let i = 0;
            for (const el of buttons) {
                i++;
                const fileName = el.getAttribute('data-file-name');
                const blob = await this.imageToBlob('#' + el.getAttribute('id'));
                zip.file(fileName + '.png', blob);
                this.saveAllStatus.done ++;
            }

            const content = await zip.generateAsync({type: "blob"})
            saveAs(content, "ig_stories.zip");

            this.saveAllStatus.in_progress = false;
            this.saveAllStatus.total = 0;
            this.saveAllStatus.done = 0;
        },
        upperCaseSlugify(str) {
            return this.slugify(str).toUpperCase();
        },
        slugify(str) {
            str = str.replace(/^\s+|\s+$/g, ''); // trim
            str = str.toLowerCase();

            // remove accents, swap ñ for n, etc
            var from = "àáãäâèéëêìíïîòóöôùúüûñç·/_,:;";
            var to = "aaaaaeeeeiiiioooouuuunc------";

            for (var i = 0, l = from.length; i < l; i++) {
                str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
            }

            str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
                .replace(/\s+/g, '-') // collapse whitespace and replace by -
                .replace(/-+/g, '-'); // collapse dashes

            return str;
        }

    },
    computed: {
        scaleRatio() {
            switch (this.assetType) {
                case "ig-story":
                    return 0.2;
                case "fb-post":
                    return 0.4;
                case "ig-feed":
                    return 0.3;
            }
            return 1;
        },
        isLoading() {
            return this.candidateFetchingInProgess || this.loginCheckInProgress
        },
        loginCheckInProgress() {
            return this.$store.getters.loginCheckInProgress
        },
    },
    async created() {
        this.album = await getCandidates(BEST_ITALIAN_RECORDS_POLL_NAME);
        this.live = await getCandidates(BEST_ITALIAN_ARTIST_SHOWS_POLL_NAME);
    }
}
</script>
