<template>
    <div style="width: 700px; height:200px;" class="relative overflow-hidden">

        <!-- Immagine candiaato -->
        <div class="absolute left-0 top-0 bottom-0 w-56 bg-red-500">
            <img :src="img" class="w-full h-full object-cover">
        </div>

        <div class="absolute inset-0 flex ">
            <div class="w-44 flex-shrink-0"></div>
            <img src="./../../../assets/img/adv/ra2021-adv-strappo.png">
            <div class="bg-black flex-grow">
                <div class="h-full p-2 pb-12 mr-3 flex flex-col items-center justify-between">
                    <div class="opacity-90">
                        <img src="./../../../assets/img/adv/ra2021-adv-logo-awards.png">
                    </div>

                    <div class="text-xl flex flex-col items-center justify-between">
                        <div class="uppercase text-center leading-none" :style="{ color: bgColor }">
                            <div v-html="name" class="font-semibold text-center leading-none"></div>
                            <div v-if="albumName" v-html="albumName" class="text-sm opacity-90 font-normal text-center leading-none"></div>
                        </div>

                        <div class="text-xs text-white uppercase mt-2">
                            <div v-if="layout === 'live'">Vota ora il miglior live italiano</div>
                            <div v-else>Vota ora il miglior album italiano</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="absolute " style="left: 250px; bottom: -15px; right: 0px;">
            <div class="flex items-center justify-between">

                <div class="pb-3 opacity-60 uppercase text-xs text-white uppercase tracking-wider font-semibold">www.rockolawards.it</div>

                <img v-if="layout === 'live'" src="./../../../assets/img/adv/ra2021-adv-logo-blu.png">
                <img v-if="layout !== 'live'" src="./../../../assets/img/adv/ra2021-adv-logo-rosso.png">
            </div>
        </div>

    </div>
</template>


<script>

export default {
    props: ['img', 'name', 'album-name', 'layout'],
    data() {
        return {
            live_bg_color: '#4d8cf6',
            album_bg_color: 'rgb(236, 14, 14)',
        }
    },
    computed: {
        bgColor() {
            return this.layout === 'live'
                ? this.live_bg_color
                : this.album_bg_color;
        },
    },
    mounted() {
        setTimeout(() => {
            textFit(this.$refs['el']);
        }, 500)
    }
}
</script>
